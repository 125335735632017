<template>
  <div v-scroll="onScroll" class="d-flex flex-column fill-height">

    <v-app-bar app :elevate-on-scroll="!dense" :shrink-on-scroll="!dense"
               :class="['app-bar', { 'transparent': transparent }]">
      <v-container>
        <router-link to="/" class="logo ml-3 ml-sm-0">
          <logo :class="[transparent ? 'light' : 'dark']"/>
        </router-link>
        <div class="flex-grow-1"></div>
        <v-toolbar-items class="d-none d-sm-flex top nav-container" :class="transparent ? 'light' : 'dark'">
          <navigation-item-top v-for="(item, index) in navigation" :item="item" :key="index"></navigation-item-top>
        </v-toolbar-items>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"
                            :class="['d-flex', 'd-sm-none', 'menu-btn', transparent  ? 'light' : 'dark']">
          <v-icon size="36">{{ $vuetify.icons.values.mdiMenu }}</v-icon>
        </v-app-bar-nav-icon>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer disable-resize-watcher v-model="drawer" right app class="side nav-container" id="side-nav">
      <v-list nav dense>
        <navigation-item-side v-for="(item, index) in navigation" :item="item" :key="index"></navigation-item-side>
      </v-list>
    </v-navigation-drawer>

    <v-main :class="['ma-0', {'pa-0': !dense}]">
      <router-view></router-view>
    </v-main>

    <div class="flex-grow-1"></div>

    <v-footer>
      <v-container>
        <footer-content/>
        <div>&copy; {{ new Date().getFullYear() }}</div>
      </v-container>
    </v-footer>

  </div>
</template>

<script>

  import Logo from "./Logo";
  import FooterContent from "./Footer";
  import NavigationItemTop from "./NavigationItemTop";
  import NavigationItemSide from "./NavigationItemSide";

  export default {
    head: {
      meta: [{
        vmid: "description",
        name: "description",
        content: "Bei uns in Stein am Rhein findet ihr Raum zum Sein, für Yoga, Tanz und Bewegung, für Entspannung, Genuss, Freude und Gesundheit."
      }]
    },
    components: {
      Logo,
      FooterContent,
      NavigationItemTop,
      NavigationItemSide
    },
    data: () => ({
      offsetTop: 0,
      drawer: false,
      navigation: [
        {
          path: "/", label: "Home", children: [
            {path: "#news", label: "News"},
            {path: "#about", label: "Über mich"}
          ]
        },
        {
          path: "/astro", label: "Astrologie", children: [
            {path: "#events", label: "Astrologie Events"},
            {path: "#horoscope", label: "Horoskop Analyse"},
            {path: "#coaching", label: "Astrologisches Coaching"},
            {path: "#learn", label: "Astrologie lernen"},
            {path: "#cosmic", label: "Cosmic Monday"}
          ]
        },
        {
          path: "/yoga", label: "Yoga", children: [
            {path: "#events", label: "Yoga Events"},
            {path: "#offer", label: "Angebot"},
            {path: "#timetable", label: "Stundenplan"},
            {path: "#private", label: "Privatlektion"},
            {path: "#prices", label: "Preise"},
            {path: "#locations", label: "Locations"}
          ]
        },
        {path: "/rituals", label: "Rituale"},
        {
          path: "/retreats", label: "Retreats", children: [
            {path: "/stein-am-rhein", label: "Stein am Rhein"},
            {path: "/algarve", label: "Algarve"},
            {path: "/weekends", label: "Wochenenden"}
          ]
        }
      ]
    }),
    methods: {
      onScroll() {
        this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
      }
    },
    computed: {
      transparent: function () {
        return this.offsetTop === 0 && !this.dense;
      },
      dense: function () {
        return this.$route.matched.some(r => r.meta.dense);
      }
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

  .app-bar {
    background: white !important;

    &.transparent {
      background: linear-gradient(180deg, rgba(0, 0, 0, .2) 0%, transparent 80%) !important;
    }

    .logo {
      height: 50%;
      max-width: 75%;
      cursor: pointer;
      align-self: center;

      > * {
        height: 100%;
        max-width: 100%;

        &.light {
          fill: white;
        }

        &.dark {
          fill: $christiane-primary;
        }
      }
    }

    .menu-btn {
      align-self: center;

      &.light {
        color: white;
      }

      &.dark {
        color: $christiane-primary;
      }
    }

    > :first-child {
      padding-left: 0;
      padding-right: 0;

      .container {
        height: 100%;
        display: flex;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .nav-container {
    text-transform: uppercase;
    font-family: Oswald, sans-serif;

    &.side a,
    &.side .nav-item,
    &.top .v-btn {
      font-size: 1.25rem !important;
    }

    &.light .v-btn {
      color: white;
    }

    &.side a,
    &.side .nav-item,
    &.dark .v-btn {
      color: $christiane-primary !important;
    }
  }

  /* Workaround, ID selector needed because of https://github.com/vuetifyjs/vuetify/issues/9285 */
  #side-nav a,
  #side-nav .nav-item {
    color: $christiane-primary !important;
  }

  footer {
    background: lighten($christiane-secondary, 35%) !important;
  }

</style>
