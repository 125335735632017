import VueRouter from "vue-router"
import VueMeta from "vue-meta"
import Vue from "vue"
import Bookings from "./components/Public/Bookings";
import Wrapper from "./components/Public/Wrapper.vue";
import Weekends from "./components/Public/Weekends.vue";
import Algarve from "./components/Public/Algarve.vue";
import SteinAmRhein from "@/components/Public/SteinAmRhein.vue";
import Register from "./components/Public/Register";
import Rituals from "@/components/Public/Rituals.vue";
import Astro from "@/components/Public/Astro.vue";
import Profile from "./components/Public/Account";
import Event from "./components/Public/Event.vue";
import Forgot from "./components/Public/Forgot";
import Home from "./components/Public/Home.vue";
import Yoga from "./components/Public/Yoga.vue";
import Login from "./components/Public/Login";

import {navItems} from "./utils";

const ABookings = () => import(/* webpackChunkName: "admin" */ "./components/Admin/Bookings.vue");
const AWrapper = () => import(/* webpackChunkName: "admin" */ "./components/Admin/Wrapper.vue");
const ALessons = () => import(/* webpackChunkName: "admin" */ "./components/Admin/Lessons.vue");
const ALesson = () => import(/* webpackChunkName: "admin" */ "./components/Admin/Lesson.vue");
const AEvents = () => import(/* webpackChunkName: "admin" */ "./components/Admin/Events.vue");
const AEvent = () => import(/* webpackChunkName: "admin" */ "./components/Admin/Event.vue");
const AFiles = () => import(/* webpackChunkName: "admin" */ "./components/Admin/Files.vue");
const AUsers = () => import(/* webpackChunkName: "admin" */ "./components/Admin/Users.vue");
const AUser = () => import(/* webpackChunkName: "admin" */ "./components/Admin/User.vue");
const ANews = () => import(/* webpackChunkName: "admin" */ "./components/Admin/News.vue");

Vue.use(VueMeta, {keyName: "head"});
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    { // Catch All
      path: "*", redirect: "/"
    },
    { // Public Routes
      path: "/", name: "public", component: Wrapper, children: [
        {path: "", component: Home},
        {path: "yoga", component: Yoga},
        {path: "astro", component: Astro},
        {path: "rituals", component: Rituals},
        {path: ":type(yoga|astro|rituals|retreats)/:id([0-9]+)", component: Event},
        {
          path: "retreats", component: {render: h => h('router-view')}, children: [
            {path: "", redirect: "algarve"},
            {path: "stein-am-rhein", component: SteinAmRhein},
            {path: "algarve", component: Algarve},
            {path: "weekends", component: Weekends}
          ]
        },
        { // User Routes
          path: "user", component: {render: h => h('router-view')}, meta: {dense: true}, children: [
            {path: "bookings/:id([0-9]+)", component: Bookings, meta: {protected: true}},
            {path: "bookings", component: Bookings, meta: {protected: true}},
            {path: "account", component: Profile, meta: {protected: true}},
            {path: "register", component: Register},
            {path: "forgot", component: Forgot},
            {path: "login", component: Login}
          ]
        },
        { // Admin Routes
          path: "admin", component: AWrapper, meta: {protected: true, dense: true}, children: [
            {path: "", redirect: () => navItems()[0] || "events"},
            {path: "events", component: AEvents, meta: {object: "event", nav: "Events"}},
            {path: "events/:id([0-9]+|new)", component: AEvent, meta: {object: "event"}},
            {path: "lessons", component: ALessons, meta: {object: "lesson", nav: "Lektionen"}},
            {path: "lessons/:id([0-9]+|new)", component: ALesson, meta: {object: "lesson"}},
            {path: "bookings", component: ABookings, meta: {object: "booking", nav: "Buchungen"}},
            {path: "files", component: AFiles, meta: {object: "file", nav: "Dateien"}},
            {path: "news", component: ANews, meta: {object: "news", nav: "Aktuell"}},
            {path: "users", component: AUsers, meta: {object: "user", nav: "Benutzer"}},
            {path: "users/:id([0-9]+|new)", component: AUser, meta: {object: "user"}}
          ]
        }
      ]
    }
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {selector: to.hash};
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return {x: 0, y: 0};
    }
  }
});

export default router
