<template>
  <div>

    <parallax image="landing-stein-am-rhein.jpg"></parallax>

    <module>
      <h1>Raum 18 Yoga Sommer-Retreat</h1>
      <h2 class="retreat-date">03. - 07. August 2025</h2>
      <v-row>
        <v-col cols="12">
          Warum in die Ferne schweifen, wenn das Gute und Schöne so nahe liegt...
        </v-col>
        <v-col cols="12">
          Einer der Gedanken, der mich bewogen hat, das diesjährige Sommer Yoga-Retreat im Raum 18 zu veranstalten. Denn
          nicht umsonst ist die Gegend hier am Rhein und am Untersee eine so beliebte Ferien Destination. Vor allem im
          Sommer.
        </v-col>
        <v-col cols="12">
          Soll ich heute im Rhein oder im See baden? Soll ich meine Yogamatte am Rheinufer- oder in der Badi ausrollen?
          Das sind Fragen, die wir uns hier im Sommer stellen.
          Welch ein Privileg! Welch ein genialer Ausgangspunkt für ein Sommer-Retreat! Und was für ein kraftvolles
          Datum, das wir ganz intuitiv für dieses Retreat gewählt haben!
        </v-col>
        <v-col cols="12">
          Denn genau im August zeigt sich die Himmelsarchitektur erstmals mit einer absolut einzigartigen Konstellation,
          der sogenannten «geistigen Triade». Heisst, die drei überpersönlichen Planeten Uranus, Neptun und Pluto stehen
          in einem harmonischen blauen Dreieck / Trigon zueinander und zeigen mit dieser Formation an, dass wir als
          Menschheit Neuland betreten, in jeder Hinsicht. Wie schön, miteinander diese neue Zeitqualität willkommen
          heissen- und bewusst miteinander erleben zu dürfen.
        </v-col>
        <v-col cols="12">
          Geniesse diesen gemeinsamen Raum, den wir zusammen kreieren werden, die Yoga Stunden am Rhein sowie in den
          schönen und lichtdurchfluteten Räumlichkeiten von Raum 18. Meditiere mit uns auf der Kraftinsel Werd, geniesse
          liebevoll gekochtes Essen, tauche ein in die klaren und reinigenden Gewässer des Rheins oder zieh dich zurück,
          mit deinem Lieblingsbuch, in die Stille der Natur oder in dir selbst.
        </v-col>
        <v-col cols="12">
          Optional kannst du dich auch mit einer ayurvedischen Massage verwöhnen lassen oder mithilfe eines
          astrologischen Coachings ein noch tieferes Verständnis für dich selbst gewinnen.
        </v-col>
        <v-col cols="12">
          <h2>Im Preis inbegriffen</h2>
          <ul class="retreat-list">
            <li>4 liebevolle, hausgemachte und vegetarische Brunches</li>
            <li>4 vegetarische 3-Gang Menüs am Abend</li>
            <li>Meteoriten-Gong Sound Bath zur Begrüssung</li>
            <li>Astrologischer Einblick in die aktuelle Zeitqualität</li>
            <li>4 Yoga- und Meditations-Einheiten am Morgen</li>
            <li>3 Yoga- und Klang Einheiten am Nachmittag</li>
            <li>Auf Wunsch diverse begleitete Kurztrips in der Umgebung (zB. Kraftinsel Werd, Burg Hohenklingen, Badi
              Espi)
            </li>
          </ul>
          Alle Yoga Lektionen sind sowohl für Yoga EinsteigerInnen sowie für erfahrene Yoginis
          gleichermassen geeignet (wobei etwas Yoga Erfahrung sicherlich von Vorteil ist).
          Yogamatten, Blöcke und Bolster stehen vor Ort für Dich zur Verfügung.
        </v-col>
        <v-col cols="12">
          <h2>Deine Investition</h2>
          CHF 875 pro Person
        </v-col>
        <v-col cols="12">
          <h2>Übernachtung</h2>
          Die Übernachtung ist nicht im Preis inbegriffen. Je nach Budget und deinen Vorstellungen/Wünschen stehen
          folgende Optionen zur Verfügung:
          <ul class="retreat-list">
            <li>Du wohnst in der Umgebung und möchtest einfach am Programm teilnehmen</li>
            <li>Du übernachtest ganz yogisch auf einer Matratze und mit Schlafsack in einem der gemütlichen Räume von
              Raum 18<br>
              Zwei Zimmer verfügen über ein Waschbecken im Zimmer, Dusche und Toiletten vorhanden<br>
              CHF 50 pro Person/Nacht
            </li>
            <li>Du übernachtest ganz yogisch auf einer Matratze und mit Schlafsack in deinem Zelt im ruhigen und kühlen
              Garten von Raum 18<br>
              Du kannst die Infrastruktur (Dusche und Toiletten) im Raum 18 nutzen<br>
              CHF 50 pro Person/Nacht
            </li>
            <li>Du wohnst ganz in der Nähe, hoch über dem Untersee im wunderschönen Schlosshotel Freudenfels in Eschenz
              (Distanz mit dem Auto, ca. 5 Minuten)<br>
              Bitte buche dein Zimmer direkt unter <a
                  href="https://schloss-freudenfels.ch/de/">schloss-freudenfels.ch</a> (Vermerk Raum 18 Yoga-Retreat /
              Christiane Beck)<br>
              CHF 155 pro Person/Nacht
            </li>
            <li>Du wohnst direkt am See, im ebenfalls traumhaft schönen See- und Parkhotel Feldbach in Steckborn
              (Distanz mit dem Auto/Zug, ca 15 Minuten)<br>
              Bitte buche dein Zimmer direkt unter <a href="https://hotel-feldbach.ch">hotel-feldbach.ch</a><br>
              CHF 110 - 170 pro Person/Nacht
            </li>
          </ul>
        </v-col>
        <v-col cols="12">
          <h2>Allgemeine Geschäftsbedingungen</h2>
          Bitte beachte auch unsere <a :href="getFileSrc('AGB-Yoga-Ferien-und-Retreats.pdf')" target="_blank"
                                       rel="noopener">
          allgemeinen Geschäftsbedingungen</a>.
        </v-col>
      </v-row>
    </module>

  </div>
</template>

<script>

  import Module from "./Module";
  import Masonry from "./Masonry";
  import Parallax from "./Parallax";

  import {getFileSrc} from "@/utils";

  export default {
    head: {
      title: "Reisen",
      meta: [{
        vmid: "description",
        name: "description",
        content: "Wir bieten regelmässig Yogareisen an, die uns an kraftvolle sowie mit Liebe und Achtsamkeit geführte Orte führen. Dort wollen wir gemeinsam Yoga praktizieren, meditieren und geniessen."
      }]
    },
    components: {
      Module,
      Masonry,
      Parallax
    },
    data() {
      return {}
    },
    methods: {
      getFileSrc
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

  .retreat-list {
    margin: 0 0 16px 16px;

    span {
      font-weight: bold;
    }
  }

</style>
